<template>
  <b-card class="profile-form">
    <b-form-group>
      <label>Name</label>
      <b-form-input
        v-model="internalValue.name"
      />
    </b-form-group>

    <b-form-group>
      <label>Email</label>
      <b-form-input
        v-model="internalValue.email"
        type="email"
      />
    </b-form-group>
  </b-card>
</template>

<script>
export default {
  name: 'ProfileForm',

  props: {
    value: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      internalValue: {
        name: '',
        email: ''
      }
    }
  },

  mounted () {
    if (this.value) {
      this.internalValue = JSON.parse(JSON.stringify(this.value))
    }
    this.emitInput()
  },

  methods: {
    emitInput () {
      this.$emit('input', this.internalValue)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
