<template>
  <div class="profile-edit-page">
    <h2>Edit Profile</h2>

    <profile-form
      v-if="user"
      v-model="user"
      class="my-3"
    />

    <b-button @click="submit">Save</b-button>
  </div>
</template>

<script>
import ProfileForm from '../components/ProfileForm.vue'

export default {
  name: 'ProfileEdit',

  components: {
    ProfileForm
  },

  data () {
    return {
      user: null
    }
  },

  created () {
    this.fetch()
  },

  methods: {
    async fetch () {
      try {
        const resp = await this.$profile.get()
        this.user = resp.data.data
        this.$store.commit('auth/SET_USER', this.user)
      }
      catch (e) {
        console.error(e.message)
      }
    },

    validate () {
      const valid = true
      return valid
    },

    async submit () {
      if (!this.validate()) {
        console.warn('Invalid form')
        return
      }

      try {
        const data = {
          name: this.user.name,
          email: this.user.email
        }
        await this.$profile.update(data)
        this.$store.commit('auth/SET_USER', JSON.parse(JSON.stringify(this.user)))
      }
      catch (e) {
        console.error(e.message)
      }
    }
  }
}
</script>

<style lang="scss">
</style>
